import React, { useRef, useState, useEffect } from 'react';
import 'styles/main.css';

import { animated, useSpring } from 'react-spring';
import { loadStripe } from '@stripe/stripe-js';
import moment from 'moment-timezone';
import smoothscroll from 'smoothscroll-polyfill';
import update from 'immutability-helper';

import API from 'files/api.js';
import { AboutUs, ContactSurvey, MobileAppSupport, NetworkSolutions, OmniShieldAppHeader } from 'managers/Home.js';
import Alert from 'views/Alert.js';
import AlertStack from 'views/AlertStack.js';
import Appearance from 'styles/Appearance.js';
import Button from 'views/Button.js';
import { CertificationTestOnboarding, TraineeSignup } from 'managers/Users.js';
import { CustomGlobalDataSurvey, EmergencyContactOnboardingSurvey, LeadGenerationSurvey, RecruitingSurvey } from 'managers/GlobalData.js';
import { EndIndex } from 'structure/Layer.js';
import DatePicker from 'views/DatePicker.js';
import Events from 'managers/Events.js';
import { Footer } from 'managers/Components.js';
import { GoogleReviews } from 'managers/Testimonials.js';
import { PrivacyPolicy } from 'managers/Privacy.js';
import QueryString from 'query-string';
import Request from 'files/Request.js';
import Sheet from 'views/Sheet.js';
import Utils from 'files/Utils.js';

const EventsManager = Events.new();
const HeaderHeight = 90;
const Stripe = loadStripe(API.stripe);

const panels = {
    home: [{
        id: 'omnishield_app_header',
        Component: OmniShieldAppHeader
    },{
        id: 'network',
        Component: NetworkSolutions
    },{
        id: 'about',
        Component: AboutUs
    },{
        id: 'contact_survey',
        Component: ContactSurvey
    }],
    careers: [{
        id: 'recruiting_survey',
        Component: RecruitingSurvey
    }],
    global_data_survey: [{
        id: 'custom_global_data_survey',
        Component: CustomGlobalDataSurvey
    }],
    emergency_contact_onboarding_survey: [{
        id: 'emergency_contact_onboarding_survey',
        Component: EmergencyContactOnboardingSurvey
    }],
    mobile_app_support: [{
        id: 'mobile_app_support',
        Component: MobileAppSupport
    }],
    privacy: [{
        id: 'privacy_policy',
        Component: PrivacyPolicy
    }],
    promotions: [{
        id: 'lead_generation_survey',
        Component: LeadGenerationSurvey
    }],
    testimonials: [{
        id: 'google_reviews',
        Component: GoogleReviews
    }],
    trainee_signup: [{
        id: 'trainee_signup',
        Component: TraineeSignup
    }],
    certification_test_onboarding: [{
        id: 'certification_test_onboarding',
        Component: CertificationTestOnboarding
    }]
}

const App = () => {

    const activeRef = useRef({});
    const dealershipRef = useRef(null);
    const layersRef = useRef(null);
    const omnishieldPreferencesRef = useRef(null);

    const [active, setActive] = useState({ view: null });
    const [alerts, setAlerts] = useState([]);
    const [affiliationBannerAnimations, setAffiliationBannerAnimations] = useSpring(() => ({
        config: { mass: 1, tension: 180, friction: 22 },
        bottom: -window.innerHeight
    }));
    const [contentAnimations, setContentAnimations] = useSpring(() => ({
        config: { mass: 1, tension: 180, friction: 16 },
        opacity: 1,
        top: 0,
    }));
    const [datePicker, setDatePicker] = useState(null);
    const [dealership, setDealership] = useState(null);
    const [layers, setLayers] = useState([]);
    const [layerIndex, setLayerIndex] = useState([]);
    const [loader, setLoader] = useState(null);
    const [logoAnimations, setLogoAnimations] = useSpring(() => ({
        config: { mass: 1, tension: 180, friction: 26 },
        left: -50,
        opacity: 0
    }));
    const [menuAnimations, setMenuAnimations] = useSpring(() => ({
        config: { mass: 1, tension: 180, friction: 26 },
        right: -50,
        opacity: 0
    }));
    const [menuDrawerContentAnimations, setMenuDrawerContentAnimations] = useSpring(() => ({
        config: { mass: 1, tension: 180, friction: 18 },
        left: 0
    }));
    const [menuDrawerAnimations, setMenuDrawerAnimations] = useSpring(() => ({
        config: { mass: 1, tension: 180, friction: 18 },
        left: window.innerWidth
    }));
    const [menuExpanded, setMenuExpanded] = useState(false);
    const [omnishieldPreferences, setOmnishieldPreferences] = useState(null);
    const [preflight, setPreflight] = useState(false);
    const [selectedNavigationItem, setSelectedNavigationItem] = useState(false);
    const [nonce, setNonce] = useState(moment().unix());
    const [sheet, setSheet] = useState(null);
    const [size, setSize] = useState({ height: window.innerHeight, width: window.innerWidth });
    const [theme, setTheme] = useState('light');
    const [waypoint, setWaypoint] = useState(null);

    const onActiveViewChange = previous => {

        // close navigation menu for mobile devices
        if(Utils.isMobile() === true) {
            setMenuExpanded(false);
        }

        // scroll to top of page
        if(previous.view !== active.view) {
            window.scrollTo({ top: 0 });
        }

        // update page title if dealership is available
        if(dealership) {
            document.title = active.view !== 'home' ? `${dealership.name}: ${getTitleForView(active.view)}` : dealership.name;
        }

        // determine if the document should scroll to a waypoint, set a timeout if the view has just changed
        if(waypoint) {
            setTimeout(() => {
                let el = document.getElementById(`navigation_component_${waypoint}`);
                if(el) {
                    window.scrollTo({ 
                        behavior: 'smooth',
                        top: el.offsetTop
                    });
                }
            }, previous.view !== active.view ? 250 : 0);
        }

        // update active ref for next navigation change
        activeRef.current = active;
    }

    const onAffiliationCloseClick = () => {
        setAffiliationBannerAnimations({ bottom: -size.height });
    }
    
    const onCloseLayer = layerID => {

        // filter layer id out of layer indexes
        setLayerIndex(layerIndex => {
            return update(layerIndex, {
                $apply: ids => ids.filter(id => id !== layerID)
            });
        });

        setLayers(layers => {

            // update layer and set visible flag to false
            let next = update(layers, {
                $apply: layers => layers.map(layer => {
                    if(layer.id === layerID) {
                        layer.visible = false;
                    }
                    return layer;
                })
            });

            // determine if at least one layer is still visible
            let targets = next.filter(layer => layer.visible !== false);
            if(targets.length === 0) {
                console.log('layers reset');
                document.body.style.overflowY = 'scroll';
                return [];
            }
            return next;
        });
    }

    const onLayerReposition = ({ id, position }) => {

        let index = layers.findIndex(layer => id === layer.id);
        if(index < 0) {
            console.log('no layer index');
            return;
        }
        setLayers(layers => {
            return update(layers, {
                [index]: {
                    position: {
                        $set: position
                    }
                }
            });
        });
    }

    const onMouseEnterMainContent = () => {

        // deselect currently selected navigation item some menu will hide
        setSelectedNavigationItem(false);
    }

    const onNavigationItemClick = item => {

        // set active view and optional waypoint
        setActive({ view: item.view });
        setWaypoint(item.waypoint);
    }

    const onOpenLayer = nextLayer => {

        if(layers.find(layer => {
            return layer.id === nextLayer.id && layer.visible !== false
        })) {
            utils.alert.show({
                title: nextLayer.abstract ? nextLayer.abstract.getTitle() : 'Just a Second',
                message: `There is already a window open for ${nextLayer.abstract ? `"${nextLayer.abstract.getTitle()}"` : 'this information'}`
            });
            return;
        }

        setTimeout(() => {
            setLayers(layers => update(layers, {
                $push: [nextLayer]
            }))
            setLayerIndex(layerIndex => update(layerIndex, {
                $unshift: [nextLayer.id]
            }))
        }, 0)
    }

    const onPhoneNumberClick = () => {
        window.open(`tel://${dealership.phone_number}`);
    }

    const onSelectedNavigationItemChange = () => {

        // no determine if selected navigation item was removed
        if(selectedNavigationItem === null) {
            setMenuDrawerContentAnimations({ left: 0 });
            return;
        }

        // trigger click action and close menu
        setMenuExpanded(false);
        if(selectedNavigationItem) {
            onNavigationItemClick(selectedNavigationItem);
        }

        // reset selected navigation item
        if(menuExpanded === false) {
            setSelectedNavigationItem(null);
        }
    }

    const onSetLayerIndex = layerID => {

        let index = layers.findIndex(layer => layer.id === layerID);
        if(index < 0) {
            console.log('no layer index');
            return;
        }
        setLayers(layers => {
            return update(layers, {
                $apply: layers => layers.map(layer => {
                    layer.moveToFront = layer.id === layerID;
                    return layer;
                })
            });
        })

        let _index = layerIndex.findIndex(id => id === layerID);
        setLayerIndex(layerIndex => {
            return update(layerIndex, {
                $splice: [
                    [_index, 1],
                    [0, 0, layerID]
                ]
            });
        });
    }

    const onSetStyleSheetProperties = () => {
        setTheme(window.theme);
        document.body.className = window.theme;
        document.documentElement.style.setProperty('--theme', window.theme);
        document.documentElement.style.setProperty('--text', Appearance.colors.text());
        document.documentElement.style.setProperty('--textfield', Appearance.colors.textField());
        document.documentElement.style.setProperty('--soft_border', Appearance.colors.softBorder());
        document.querySelector('meta[name="theme-color"]').setAttribute("content", Appearance.colors.layerBackground());
    }

    const onShowMobileAppSupport = () => {
            
    }

    const onSizeChange = () => {
        setSize({ height: window.innerHeight, width: window.innerWidth });
    }

    const onToggleMobileMenu = () => {
        setMenuExpanded(val => !val);
        setSelectedNavigationItem(null);
    }

    const onUpdateTheme = evt => {
        window.theme = evt.matches ? 'dark' : 'light';
        onSetStyleSheetProperties();
        setNonce(moment().unix());
    }

    const getAffiliationDisclaimerBanner = () => {
        return dealership?.show_affiliation_disclaimer === true && (
            <animated.div style={{
                backgroundColor: Appearance.colors.layerBackground(),
                borderRadius: 12,
                boxShadow: '0px 0px 16px rgba(0,0,0,0.1)',
                display: 'flex',
                flexDirection: 'column',
                left: Utils.isMobile() ? 12 : null,
                maxWidth: 750,
                padding: 32,
                position: 'fixed',
                right: 12,
                ...affiliationBannerAnimations
            }}>
                <span style={{
                    color: Appearance.colors.text(),
                    fontFamily: Appearance.fonts.getFamilyForWeight(800),
                    fontSize: Utils.isMobile() ? 20 : 24,
                    lineHeight: 1.2,
                    marginBottom: 12
                }}>{'ABOUT OUR COMPANY'}</span>
                <span style={{
                    color: Appearance.colors.text(),
                    display: 'block',
                    fontFamily: Appearance.fonts.getFamilyForWeight(400),
                    fontSize: 16,
                    lineHeight: 1.5,
                    marginBottom: 24,
                    whiteSpace: 'normal',
                }}>{`${dealership.name} is a private company and is not affiliated with any government agency or municipality. We are not endorsed by any fire department or safety organization. One of our driving principles is educating families on home fire safety. We offer a range of high-quality early warning safety products for homeowners to purchase. We obtain our customers from referrals, surveys, social media, trade shows, door to door contact, and word of mouth. To request an educational evaluation for your home, contact us by clicking above or give us a call. We look forward to helping you.`}</span>
                <Button 
                color={'primary'}
                label={'Okay'}
                onClick={onAffiliationCloseClick}
                style={{
                    borderRadius: 5,
                    height: 45,
                    padding: '8px 18px 8px 18px'
                }}
                type={'large'}
                utils={utils} />
            </animated.div>
        )
    }

    const getLayers = () => {
        return layers.map(({ id, abstract, options, title, visible, Component }, index) => {

            if(visible === false) {
                // must return null instead of using filter
                // using filter for visible does not preseve other visible layers
                return null;
            }
            return (
                <Component
                abstract={abstract}
                key={index}
                index={index}
                title={title}
                utils={utils}
                options={{
                    ...options,
                    index: index,
                    onClose: onCloseLayer,
                    onReposition: onLayerReposition,
                    onSetLayerIndex: onSetLayerIndex,
                    zIndex: EndIndex - layerIndex.findIndex(indexID => id === indexID)
                }}/>
            )
        });
    }

    const getHeader = () => {

        // prevent moving forward if a dealership has not been set
        if(!dealership) {
            return null;
        }

        // prepare navigation items
        let items = getNavigationItems();

        // prepare active navigation item flag
        let activeItem = waypoint || active.view;

        // determine if current device is a mobile device
        if(Utils.isMobile() === true) {
            return (
                <>
                <div style={{
                    alignItems: 'center',
                    backgroundColor: dealership.colors.secondary || Appearance.colors.layerBackground(),
                    borderBottom: `1px solid ${Appearance.colors.divider()}`,
                    display: 'flex',
                    flexDirection: 'row',
                    height: HeaderHeight,
                    justifyContent: 'space-between',
                    left: 0,
                    padding: 20,
                    position: 'fixed',
                    textAlign: 'center',
                    top: 0,
                    width: '100%',
                    zIndex: 5000
                }}>
                    <animated.div style={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        minWidth: 0,
                        position: 'relative',
                        width: '100%',
                        ...logoAnimations
                    }}>
                        <img 
                        src={dealership.logo && dealership.logo.url || 'images/dealership-replicated-logo-placeholder.png'}
                        style={{
                            borderRadius: 8,
                            height: 50,
                            marginRight: 8,
                            objectFit: 'contain',
                            width: 50
                        }} />
                        <div style={{
                            alignItems: 'flex-start',
                            display: 'flex',
                            flexDirection: 'column',
                            minWidth: 0,
                            textAlign: 'left'
                        }}>
                            <span style={{
                                color: 'white',
                                fontSize: 24,
                                fontFamily: Appearance.fonts.getFamilyForWeight(800),
                                lineHeight: 1.2,
                                paddingRight: 12,
                                maxWidth: '100%',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap'
                            }}>{dealership.name && dealership.name.toUpperCase()}</span>
                            <span style={{
                                color: 'white',
                                fontSize: 16,
                                fontFamily: Appearance.fonts.getFamilyForWeight(500),
                                lineHeight: 1.2,
                                paddingRight: 12,
                                maxWidth: '100%',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap'
                            }}>{dealership.locality && dealership.administrative_area_level_1 ? `${dealership.locality}, ${dealership.administrative_area_level_1}` : 'Location Not Available'}</span>
                        </div>
                    </animated.div>
                    <animated.img 
                    className={'text-button'}
                    onClick={onToggleMobileMenu}
                    src={`images/navigation-icon-dark.png`}
                    style={{
                        height: 30,
                        objectFit: 'contain',
                        position: 'relative',
                        width: 30,
                        ...menuAnimations
                    }} />
                </div>
                <animated.div style={{
                    backgroundColor: Appearance.colors.layerBackground(),
                    bottom: 0,
                    height: '100%',
                    overflow: 'hidden',
                    position: 'fixed',
                    top: 0,
                    width: '100%',
                    zIndex: 5500,
                    ...menuDrawerAnimations
                }}>
                    <animated.div style={{
                         alignItems: 'center',
                         display: 'flex',
                         flexDirection: 'row',
                         height: '100dvh',
                         overflow: 'hidden',
                         position: 'relative',
                         width: size.width * 2,
                         ...menuDrawerContentAnimations
                    }}>
                        <div style={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-around',
                            height: '100%',
                            padding: `128px 20px 148px 20px`,
                            width: size.width
                        }}>
                            {items.map((item, index) => (
                                <span 
                                className={'text-button'}
                                key={index}
                                onClick={item.onClick || onNavigationItemClick.bind(this, item)}
                                style={{
                                    ...Appearance.textStyles.layerItemTitle(),
                                    fontSize: 24,
                                    ...item.key === activeItem && {
                                        color: dealership && dealership.colors.primary || Appearance.colors.primary()
                                    }
                                }}>{item.title}</span>
                            ))}
                        </div>
                        <div style={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-around',
                            height: '100%',
                            padding: `128px 20px 148px 20px`,
                            position: 'relative',
                            width: size.width
                        }}>
                            <img
                            className={'text-button'}
                            onClick={setSelectedNavigationItem.bind(this, null)}
                            src={`images/back-arrow-${theme}.png`}
                            style={{
                                height: 25,
                                objectFit: 'contain',
                                position: 'absolute',
                                left: 20,
                                top: 20,
                                width: 18
                            }} />
                        </div>
                    </animated.div>
                    <img
                    className={'text-button'}
                    onClick={onToggleMobileMenu}
                    src={`images/close-button-${theme}.png`}
                    style={{
                        height: 25,
                        objectFit: 'contain',
                        position: 'absolute',
                        right: 20,
                        top: 20,
                        width: 25
                    }} />
                    <div style={{
                        bottom: 0,
                        padding: 24,
                        position: 'absolute',
                        textAlign: 'center',
                        width: '100%'
                    }}>
                        <span style={{
                            ...Appearance.textStyles.subTitle(),
                            display: 'block'
                        }}>{`Version ${API.app_version} Build ${API.build}`}</span>
                    </div>
                </animated.div>
                </>
            )
        }
        
        // fallback to rendering content for tablets and desktop 
        return (
            <div style={{
                alignItems: 'center',
                backgroundColor: dealership.colors.secondary || Appearance.colors.layerBackground(),
                borderBottom: `1px solid ${Appearance.colors.divider()}`,
                display: 'flex',
                flexDirection: 'row',
                height: HeaderHeight,
                justifyContent: 'space-between',
                padding: '12px 12px 12px 12px',
                position: 'fixed',
                textAlign: 'center',
                width: '100vw',
                zIndex: 5000
            }}>
                <animated.div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    paddingLeft: 8,
                    position: 'relative',
                    width: 'calc(50vw - 50px)',
                    ...logoAnimations
                }}>
                    <img 
                    src={dealership.logo && dealership.logo.url || 'images/dealership-replicated-logo-placeholder.png'}
                    style={{
                        borderRadius: 8,
                        height: 60,
                        marginRight: 12,
                        objectFit: 'contain',
                        width: 60
                    }} />
                    <div style={{
                        alignItems: 'flex-start',
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <span style={{
                            color: 'white',
                            fontSize: 24,
                            fontFamily: Appearance.fonts.getFamilyForWeight(800),
                            lineHeight: 1.2
                        }}>{dealership.name && dealership.name.toUpperCase()}</span>
                        <span style={{
                            color: 'white',
                            fontSize: 16,
                            fontFamily: Appearance.fonts.getFamilyForWeight(500),
                            lineHeight: 1.2
                        }}>{dealership.locality && dealership.administrative_area_level_1 ? `${dealership.locality}, ${dealership.administrative_area_level_1}` : 'Location Not Available'}</span>
                    </div>
                </animated.div>
                <animated.div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    position: 'relative',
                    width: 'calc(50vw - 50px)',
                    ...menuAnimations
                }}>
                    {items.map((item, index) => {
                        return (
                            <div 
                            key={index}
                            style={{
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                                marginRight: 32,
                                position: 'relative'
                            }}>
                                <span 
                                className={'text-button'}
                                onClick={item.onClick || onNavigationItemClick.bind(this, item)}
                                onMouseEnter={setSelectedNavigationItem.bind(this, item.key)}
                                style={{
                                    color: 'white',
                                    fontFamily: Appearance.fonts.getFamilyForWeight(700),
                                    fontSize: 18,
                                    ...item.key === activeItem && {
                                        color: dealership && dealership.colors.primary || Appearance.colors.primary()
                                    }
                                }}>{item.title}</span>
                            </div>
                        )
                    })}
                    {dealership && (
                        <div style={{
                            marginRight: 8
                        }}>
                            <Button 
                            color={dealership.colors.primary || 'primary'}
                            label={Utils.formatPhoneNumber(dealership.phone_number)}
                            onClick={onPhoneNumberClick} 
                            style={{
                                borderRadius: 5,
                                height: 45,
                                maxWidth: 175,
                                padding: '8px 18px 8px 18px'
                            }}
                            type={'large'} />
                        </div>
                    )}
                </animated.div>
            </div>
        )
    }

    const getMainContent = () => {

        // no additional logic is required if preflight tasks have not been completed
        if(preflight === false) {
            return null;
        }

        // return a placeholder if no dealership has been set
        if(!dealership) {
            return (
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'center',
                    textAlign: 'center'
                }}>
                    <span style={{
                        color: Appearance.colors.text(),
                        fontFamily: Appearance.fonts.getFamilyForWeight(700),
                        fontSize: 24
                    }}>{'NO DEALERSHIP FOUND'}</span>
                    <span style={{
                        color: Appearance.colors.subText(),
                        fontFamily: Appearance.fonts.getFamilyForWeight(500),
                        fontSize: 16,
                        maxWidth: 500
                    }}>{'We were unable to locate a dealership using the supplied credentials. Please verify that the link you used is complete and valid.'}</span>
                </div>
            )
        }

        // determine which panels to show for a user that is already logged in
        let targets = panels[active.view] || [];
        return (
            <animated.div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                width: '100%',
                ...contentAnimations
            }}>
                {getHeader()}
                {getLayers()}
                <div 
                onMouseEnter={onMouseEnterMainContent}
                style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    paddingTop: HeaderHeight,
                    position: 'relative',
                    width: '100%'
                }}>
                    <div
                    className={'row p-0 m-0'}
                    style={{
                        position: 'relative',
                        width: '100%'
                    }}>
                        {targets.map(({ Component }, index) => (
                            <Component
                            dealership={dealership}
                            index={index} 
                            key={index}
                            omnishield={omnishieldPreferences}
                            utils={utils}/>
                        ))}
                    </div>
                </div>
                <Footer 
                dealership={dealership}
                utils={utils} />
                {getAffiliationDisclaimerBanner()}
            </animated.div>
        )
    }

    const getNavigationItems = () => {

        let { google_reviews = {} } = dealershipRef.current || {};
        if(!dealershipRef.current) {
            return [];
        }

        return [{
            key: 'home',
            title: 'Home',
            view: 'home',
            waypoint: 'home'
        },{
            key: 'network',
            title: 'Network',
            view: 'home',
            waypoint: 'network'
        },{
            key: 'testimonials',
            title: 'Testimonials',
            view: 'testimonials',
            visible: google_reviews.enabled ? true : false
        },{
            key: 'privacy',
            title: 'Privacy',
            view: 'privacy'
        },{
            key: 'about',
            title: 'About',
            view: 'home',
            waypoint: 'about'
        },{
            key: 'contact',
            title: 'Contact',
            view: 'home',
            waypoint: 'contact'
        }].filter(item => item.visible !== false);
    }

    const getTitleForView = view => {
        switch(view) {
            case 'emergency_contact_onboarding_survey':
            return 'Onboarding Survey';

            case 'global_data_survey':
            return 'Survey';

            default:
            return active.view.split('_').map(string => Utils.ucFirst(string)).join(' ');
        }
    }

    const getViewForRoute = route => {

        // no compare is required if a route was not provided
        if(!route) {
            return null;
        }

        // determine if mobile app support was requested
        if(route === 'mobible_app_support') {
            onShowMobileAppSupport();
            return;
        }

        // loop through navigation items and find matching item
        let match = getNavigationItems().find(item => item.view === route || item.waypoint === route);
        return match && match.view;
    }

    const runPreflightTasks = async () => {
        try {

            let { route } = QueryString.parse(window.location.search);

            // determine if a php error message was generated on page load
            let error = document.querySelector('meta[name="php_error_message"]');
            if(error) {
                throw new Error(error.getAttribute('content'));
            }

            // pull dealership id from meta tag, value was prepared by php script during page load
            let id = API.dev_env ? 932 : document.querySelector('meta[name="dealership_id"]').getAttribute('content');

            // fetch preferences for dealership using the web alias or custom domain
            let { access_token, dealership_id, google_business_profile, omnishield, preferences } = await Request.get(utils, '/dealerships/', {
                dealership_id: id,
                domain: window.location.hostname,
                type: 'replicated_website_preferences'
            });

            // update local state with omnishield preferences
            setOmnishieldPreferences(omnishield || {});

            // manually set dealership object for ref and update local state
            dealershipRef.current = { 
                ...preferences,
                access_token: access_token,
                google_business_profile: google_business_profile,
                id: dealership_id
            };
            setDealership(dealershipRef.current);

            // prepare view and waypoint for initial navigation
            let view = getViewForRoute(route) || 'home';
            let waypoint = route;

            // determine if a hard linked was provided
            if(['certification_test_onboarding', 'emergency_contact_onboarding_survey', 'global_data_survey', 'mobile_app_support', 'trainee_signup'].includes(route) === true) {
                view = route;
                waypoint = null;
            }
            
            // determine if a mobile app download request was submitted
            if(route === 'mobile_app_download') {

                // determine if the device is a mobile device or a tablet running ios
                let ios = /iPad|iPhone|iPod/.test(navigator.userAgent);
                let mac = navigator.userAgent.includes('Macintosh');
                if(mac === false && (Utils.isMobile() === true || ios === true)) {
                    
                    // determine which type of url to prepare
                    let platform = ios ? 'ios' : 'android';
                    switch(platform) {
                        case 'android':
                        window.location.href = omnishield && omnishield.android.play_store_url || 'https://play.google.com/store/apps/details?id=com.commlink.omnishield2';
                        return;

                        case 'ios':
                        window.location.href = omnishield && omnishield.ios.app_store_url || 'https://apps.apple.com/gb/app/omnishield/id1491369651';
                        return;
                    }
                }

                // set waypoint to network/mobile app if device is a desktop device
                if(Utils.isMobile() === false || mac === true) {
                    waypoint = 'mobile_app';
                }
            }

            // set default active values and optional routing waypoint
            setActive({ view });
            setWaypoint(waypoint);

            // amimate components into view
            setContentAnimations({ opacity: 1, top: 0 });
            setLogoAnimations({ left: 0, opacity: 1 });
            setMenuAnimations({ opacity: 1, right: 0 });

            // show affiiation disclaimer banner if applicable
            if(preferences.show_affiliation_disclaimer === true) {
                setAffiliationBannerAnimations({ bottom: 12 });
            }

            // set preflight flag as complete
            setPreflight(true);

        } catch(e) {
            console.error(e.message);
            utils.loader.hide();
            utils.alert.show({
                title: 'Just a Second',
                message: e.message || 'An unknown error occurred'
            });
        }
    }

    const utils = {
        alert: {
            show: async props => {
                try {
                    if(loader) {
                        await utils.loader.hide();
                    }
                    setAlerts(alerts => update(alerts, {
                        $push: [{
                            id: `${moment().unix()}-${Math.random()}`,
                            ...props
                        }]
                    }))
                } catch(e) {
                    console.log(e.message)
                }
            },
            dev: async () => {
                try {
                    if(loader) {
                        await utils.loader.hide();
                    }
                    setAlerts(alerts => update(alerts, {
                        $push: [{
                            id: `${moment().unix()}-${Math.random()}`,
                            title: 'In Development',
                            message: 'This feature is currently under development and will become available at a later date'
                        }]
                    }))

                } catch(e) {
                    console.log(e.message)
                }
            },
        },
        api: {
            headers: () => {
                return {
                    'Content-Type': 'application/json',
                    'X-API': `Version ${API.version}`,
                    'X-Timezone': `TZ ${moment.tz.guess()}`,
                    'X-Web': `Build ${API.build}`
                }
            }
        },
        datePicker: {
            show: props => setDatePicker({
                utils: utils,
                id: `${moment().unix()}-${Math.random()}`,
                ...props
            })
        },
        dealership: {
            get: () => dealershipRef.current,
            omnishield: {
                get: () => omnishieldPreferencesRef.current
            }
        },
        events: EventsManager,
        layer: {
            open: layer => onOpenLayer(layer),
            close: layer => onCloseLayer(layer),
            requestClose: id => {
                let closeEvent = new CustomEvent('onLayerAction', {
                    detail: {
                        action: 'close',
                        layerID: id
                    }
                });
                window.dispatchEvent(closeEvent);
            }
        },
        loader: {
            show: async () => {
                return new Promise(resolve => {
                    setLoader(true);
                    setTimeout(resolve, 500)
                });
            },
            hide: async () => {
                return new Promise(resolve => {
                    setLoader(false);
                    setTimeout(resolve, 500)
                });
            }
        },
        navigation: {
            set: onNavigationItemClick,
            setWaypointObserver: (target, identifier) => {

                // create interaction observer for element
                let observer = new IntersectionObserver(entries => {
                    entries.forEach(entry => {
                        if(entry.intersectionRatio > 0) {
                            setWaypoint(identifier);
                        }
                    });
                }, {
                    rootMargin: '0px',
                    threshold: 0
                });
        
                // add observer to component
                observer.observe(target);
            }
        },
        sheet: {
            show: (sheet, callback) => {
                setSheet({ ...sheet, onClick: callback })
            }
        },
        stripe: Stripe
    }

    useEffect(() => {
        onActiveViewChange(activeRef.current);
    }, [active, dealership]);

    useEffect(() => {
        dealershipRef.current = dealership;
    }, [dealership]);

    useEffect(() => {
        layersRef.current = layers;
    }, [layers]);

    useEffect(() => {
        if(Utils.isMobile() === false) {
            setMenuDrawerAnimations({ left: size.width });
            return;
        }
        setMenuDrawerAnimations({ left: menuExpanded ? 0 : size.width });
    }, [menuExpanded, size]);

    useEffect(() => {
        omnishieldPreferencesRef.current = omnishieldPreferences;
    }, [omnishieldPreferences]);

    useEffect(() => {
        if(Utils.isMobile() === true) {
            onSelectedNavigationItemChange();
        }
    }, [selectedNavigationItem]);

    useEffect(() => {

        runPreflightTasks();

        // setup window listeners and smoothscroll polyfill
        smoothscroll.polyfill();
        window.addEventListener('resize', onSizeChange);
        window.addEventListener('beforeunload', evt => {
            evt.returnValue = null;
            return null;
        });

        // css variables
        document.documentElement.style.setProperty('--text', Appearance.colors.text());
        document.documentElement.style.setProperty('--textfield', Appearance.colors.textField());
        document.documentElement.style.setProperty('--soft_border', Appearance.colors.softBorder());

        // theme and theme listeners
        window.theme = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
        document.documentElement.style.setProperty('--theme', window.theme);
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', onUpdateTheme);
        onSetStyleSheetProperties();

    }, []);

    return (
        <div
        className={`root-container ${theme}`}
        nonce={nonce}
        style={{
            height: size.height,
            position: 'relative',
            width: size.width
        }}>
            {getMainContent()}
            {datePicker && (
                <DatePicker
                {...datePicker}
                onClose={() => {
                    setDatePicker(null);
                    if(typeof(datePicker.onClose) === 'function') {
                        datePicker.onClose();
                    }
                }} />
            )}
            {sheet && (
                <Sheet
                {...sheet}
                onClose={() => setSheet(null)}/>
            )}
            <AlertStack>
                {alerts.map((alert, index) => (
                    <Alert
                    {...alert}
                    key={index}
                    utils={utils}
                    index={(alerts.length - 1) - index}
                    onClose={id => {
                        if(typeof(alert.onClose) === 'function') {
                            alert.onClose();
                        }
                        setAlerts(alerts => {
                            return alerts.filter(alert => {
                                return id !== alert.id;
                            });
                        })
                    }} />
                ))}
            </AlertStack>
        </div>
    )
}

export default App;
